// import { network } from './network'

/**
 * @ignore
 */
const config = {
  // network: network.defaults.MAINNET_DEFAULT,
  network: {
    layer1: 'placeholder',
  },
  logLevel: 'debug',
};

export { config };
